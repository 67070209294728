import { Typography } from "@mui/material";
import { TranslatedOption } from "../lib/field-options";
import Tooltip from "./Tooltip";

interface IIdResolverProps {
    values: number | number[];
    options?: TranslatedOption[]; // This is optional so typescript does not complain when props are injected by OptionsProvider component
}

const IdResolver = ({ options, values: valuesFromProps }: IIdResolverProps) => {
    if (options == null) {
        return null;
    }

    const values = !Array.isArray(valuesFromProps) ? [valuesFromProps] : valuesFromProps;
    const renderedValue = values
        .map((value) => options.find(({ id }) => id === value))
        .filter((option): option is TranslatedOption => option != null)
        .map(({ name }) => name)
        .join(", ");

    return (
        <Tooltip title={renderedValue}>
            <Typography noWrap>{renderedValue}</Typography>
        </Tooltip>
    );
};
export default IdResolver;
